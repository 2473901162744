import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffectOnce, useEvent, useUpdateEffect } from 'react-use';
import { useBasket, useCreateBasket } from '~/services';
import { useBasketReference, useBasketTimer } from '~/shared/utils/storage';

export default function useBasketWatcher() {
    const queryClient = useQueryClient();
    const [reference] = useBasketReference();
    const [storageUpdateTime] = useBasketTimer();
    const { data: basketUpdateTime } = useBasket((basket) => basket.updated.toString());
    const { mutate: createBasket } = useCreateBasket();

    const onFocus = useCallback(() => {
        if (!reference) {
            createBasket();
        }
    }, [reference, createBasket]);

    // Create basket whenever browser is focused if no previous basket reference is set.
    useEvent('focus', onFocus);

    // Create basket, but only if browser is in focus and no previous basket reference is set.
    useEffectOnce(() => {
        if (document.hasFocus() && !reference) {
            createBasket();
        }
    });

    // Keep basket data in sync with changes from other tabs.
    useUpdateEffect(() => {
        if (!!storageUpdateTime && !!basketUpdateTime && storageUpdateTime !== basketUpdateTime) {
            queryClient.invalidateQueries(['basket', { reference }]);
        }
    }, [storageUpdateTime, basketUpdateTime, queryClient, reference]);
}
