import { ShopTypes } from '~/lib/data-contract';
import { useTranslations } from '~/services';
import { useCurrency } from '~/shared/hooks';
import { FreightDeliveryDate } from './FreightDeliveryDate';
import {
    ContextFreightPrice,
    ContextFreightTime,
    ContextHeader,
    ContextName,
    FreightDeliveryDateWrapper,
} from './styled';

interface FreightContextHeaderProps {
    contextName: string;
    freight?: ShopTypes.BasketFreightResponse;
}

const DELIVERY_FREIGHT_TYPES = [
    ShopTypes.FreightType.AlwaysFreight,
    ShopTypes.FreightType.DirectDelivery,
    ShopTypes.FreightType.KvantumFreight,
    ShopTypes.FreightType.Stykgods,
];

export const FreightContextHeader = ({ contextName, freight }: FreightContextHeaderProps) => {
    const formatCurrency = useCurrency();
    const { translate } = useTranslations();

    if (!freight) {
        return null;
    }

    const freightIsDelivery = DELIVERY_FREIGHT_TYPES.includes(freight.freightType);

    const pickupPlace = freight.stockInfo ? freight.stockInfo?.departmentName : undefined;
    const freightName = freight?.freightTypeNameValue || freight.freightTypeName;
    const onlyRetrievalTitle = translate('basket.pickupInStore').replace(
        '[STORENAME]',
        pickupPlace || '',
    );
    const contextNameTitle = `${freightName} ${translate('basket.from')} ${contextName}`;
    const defaultTitle = `${freightName} ${
        pickupPlace ? `${translate('basket.from')} ${pickupPlace}` : ''
    }`;

    const freightDeliveryDate = freight.deliveryDate || freight.calculatedDeliveryDate;

    const freightDeliveryPrice =
        freight.freightPrice.priceInclVat === 0
            ? translate('generic.price.free')
            : freightIsDelivery
            ? formatCurrency(freight.freightPrice.priceInclVat)
            : `${translate('basket.from')} ${formatCurrency(freight.freightPrice.priceInclVat)}`;

    return (
        <ContextHeader>
            <ContextName>
                {freight.freightType === ShopTypes.FreightType.OnlyRetrieval
                    ? onlyRetrievalTitle
                    : contextName
                    ? contextNameTitle
                    : defaultTitle}
            </ContextName>

            <ContextFreightTime>
                {!freightIsDelivery ||
                !freightDeliveryDate ||
                freight.freightType === ShopTypes.FreightType.DirectDelivery ? (
                    freight.deliveryTitle && (
                        <p>
                            {translate(freight.deliveryTitle?.name, freight.deliveryTitle?.values)}
                        </p>
                    )
                ) : (
                    <FreightDeliveryDateWrapper>
                        <FreightDeliveryDate
                            deliveryDate={freightDeliveryDate}
                            deliveryId={freight.deliveryId}
                        />

                        {freight.deliveryAddress?.postalCode
                            ? `${translate('basket.freightPriceText')}${freight.deliveryAddress
                                  ?.postalCode}`
                            : null}

                        {/* TODO: FreightPrice calculation on basket postponed to phase 2 */}
                        {/* {freight?.brands?.[0]?.items?.[0]?.itemNumber && (
                            <FreightDeliveryPrice
                                itemNumber={freight.brands[0].items[0].itemNumber}
                            />
                        )} */}
                    </FreightDeliveryDateWrapper>
                )}
            </ContextFreightTime>

            <ContextFreightPrice>{freightDeliveryPrice}</ContextFreightPrice>
        </ContextHeader>
    );
};
