import NextLink from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

type Props = {
    items: { text: string; icon: React.ReactNode; url?: string }[];
    isHorizontal?: boolean;
    mobileHiddenText?: boolean;
    noBackground?: boolean;
};

export default function IconList({
    items,
    isHorizontal = false,
    mobileHiddenText = false,
    noBackground,
}: Props) {
    return (
        <List isHorizontal={isHorizontal} noBackground={Boolean(noBackground)}>
            {items.map(({ url, text, icon }) => (
                <Item key={text} title={text}>
                    {url ? (
                        <NextLink href={url} passHref legacyBehavior>
                            <Link>
                                {icon}
                                {mobileHiddenText ? (
                                    <MobileHiddenText>{text}</MobileHiddenText>
                                ) : (
                                    text
                                )}
                            </Link>
                        </NextLink>
                    ) : (
                        <>
                            {icon}
                            {mobileHiddenText ? <MobileHiddenText>{text}</MobileHiddenText> : text}
                        </>
                    )}
                </Item>
            ))}
        </List>
    );
}

const alignCenter = css({
    display: 'flex',
    alignItems: 'center',
});

const List = styled.ul<{ isHorizontal?: boolean; noBackground?: boolean }>(
    ({ theme, isHorizontal, noBackground }) => ({
        display: 'flex',
        flexDirection: isHorizontal ? 'row' : 'column',
        gap: theme.space[3],
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.dog,

        svg: {
            color: theme.colors.offWhite,
            width: theme.space[5],
            height: theme.space[5],
            marginRight: theme.space[2],
            backgroundColor: noBackground ? 'transparent' : theme.colors.brandBlue,
            padding: 6,
            borderRadius: 2,
        },
    }),
);

const Item = styled.li(alignCenter, {
    listStyleType: 'none',
});

const Link = styled.a(alignCenter, {
    color: 'inherit',
    textDecoration: 'none',
});

const MobileHiddenText = styled.span({
    display: 'none',

    [breakpoints.md]: {
        display: 'inline',
    },
});
