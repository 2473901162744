import CalenderIcon from '~/icons/illustrations/calender.svg';
import { useTranslations } from '~/services';
import { Link } from '~/shared/components/Link';
import { capitalizeFirstLetter, formatDateLongDayAndLongMonth } from '~/shared/utils/helpers';
import useModalState from '~/state/useModalState';
import { FreightDeliveryDateLink } from './styled';

interface FreightDeliveryDateProps {
    deliveryDate?: Date;
    deliveryId: number;
}

export const FreightDeliveryDate = ({ deliveryDate, deliveryId }: FreightDeliveryDateProps) => {
    const { translate } = useTranslations();
    const { setDeliveryDateModalId } = useModalState();

    return (
        <FreightDeliveryDateLink flow="row" align="center">
            <CalenderIcon height="16" width="16" />
            <Link
                onClick={() => {
                    setDeliveryDateModalId(deliveryId);
                }}
            >
                {translate('checkout.chooseDeliveryDate')}:{' '}
                {deliveryDate
                    ? capitalizeFirstLetter(formatDateLongDayAndLongMonth(deliveryDate))
                    : null}
            </Link>
        </FreightDeliveryDateLink>
    );
};
