import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { Text } from '../Text';

export const Backdrop = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.nightBlack,
    inset: 0,
    opacity: '0.7',
    position: 'absolute',
    width: '100%',
    userSelect: 'none',
}));

export const ConfirmationSubTitle = styled(Text)(({ theme }) => ({ marginBottom: theme.space[4] }));

export const ConfirmationTitle = styled.div(({ theme }) => ({
    alignItems: 'start',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.space[4],

    svg: {
        cursor: 'pointer',
    },
}));

export const ConfirmationWrapper = styled.div(({ theme }) => ({
    alignContent: 'center',
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexFlow: 'column',
    fontWeight: theme.fontWeights.normal,
    padding: theme.space[4],
    position: 'fixed',
    right: '50%',
    top: '50%',
    transform: 'translate(50%, -50%)',
    width: '90%',
    zIndex: theme.zIndices.max,

    '> button': {
        marginBottom: theme.space[2],
    },

    [breakpoints.sm]: {
        padding: theme.space[6],
        maxWidth: '400px',
    },
}));
