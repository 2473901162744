import styled from '@emotion/styled';

export const BasketWrapper = styled.ul(({ theme }) => ({
    display: 'grid',
    gap: theme.space[5],
    position: 'relative',
}));

export const Loading = styled.div(({ theme }) => ({
    svg: {
        fill: theme.colors.thunderBlack,
    },
}));
