import { useEffect } from 'react';
import { Portal } from 'react-portal';
import CloseIcon from '$icons/iconography/close.svg';
import { useScrollLock } from '~/shared/hooks';
import { Button } from '../Button';
import { Text } from '../Text';
import { Backdrop, ConfirmationSubTitle, ConfirmationTitle, ConfirmationWrapper } from './styled';

interface ConfirmationProps {
    cancelText: string;
    confirmText: string;
    onConfirm: () => void;
    onCancel: () => void;
    title: string;
    subTitle?: string;
}

export const Confirmation = ({
    cancelText,
    confirmText,
    onConfirm,
    onCancel,
    subTitle,
    title,
}: ConfirmationProps) => {
    const { lock, unlock } = useScrollLock();

    // Lock scroll when modal is open
    useEffect(() => {
        lock();
        return () => unlock();
    }, [lock, unlock]);

    return (
        <Portal>
            <Backdrop onClick={onCancel} />

            <ConfirmationWrapper>
                <ConfirmationTitle>
                    <Text as="h2" variant="display4">
                        {title}
                    </Text>

                    <CloseIcon onClick={onCancel} height="24px" width="24px" />
                </ConfirmationTitle>

                {subTitle ? (
                    <ConfirmationSubTitle variant="paragraph">{subTitle}</ConfirmationSubTitle>
                ) : null}

                <Button onClick={onConfirm}>{confirmText}</Button>

                <Button onClick={onCancel} variant="stroke">
                    {cancelText}
                </Button>
            </ConfirmationWrapper>
        </Portal>
    );
};
