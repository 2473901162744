import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Flex } from '~/shared/components/Basics';
import { Link } from '~/shared/components/Link';
import { breakpoints } from '~/theme';

export const LineItem = styled.div(({ theme }) => ({
    display: 'grid',
    padding: theme.space[1],
    borderBottom: `1px solid ${theme.colors.grey40}`,
    backgroundColor: theme.colors.offWhite,
    transition: 'width .2s ' + theme.easings.smoothEase,
    gap: 0,
    rowGap: 2,
    width: '100%',
    gridTemplateColumns: `min-content 1fr clamp(5ch, 15%, 50px)`,
    gridTemplateAreas:
        '"image type remove" "image name remove" "image variant variant" "image itemNumber itemNumber" "amount amount amount" "error error error"',

    [`> p`]: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },

    [breakpoints.sm]: {
        [`> p, > a`]: {
            WebkitLineClamp: 2,
        },
    },

    [breakpoints.md]: {
        padding: theme.space[3],
        rowGap: theme.space[1],
    },

    [breakpoints.lg]: {
        rowGap: theme.space[1],
        gridTemplateColumns: `min-content 1fr clamp(35ch, 60%, 400px) clamp(5ch, 15%, 60px)`,
        gridTemplateAreas:
            '"image type type remove" "image name name remove" "image variant variant variant" "image itemNumber amount amount" "error error error error"',
    },
}));

export const ContextHeader = styled.div(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.thunderBlack}`,
    display: 'grid',
    gridTemplateAreas: '"name name" "time price"',
    gridTemplateColumns: '1fr auto',
    columnGap: theme.space[2],
    paddingBottom: theme.space[3],
    rowGap: theme.space[2],
}));

export const ContextName = styled.p(({ theme }) => ({
    gridArea: 'name',
    fontWeight: theme.fontWeights.medium,
    fontSize: theme.fontSizes.cat,
    lineHeight: theme.lineHeights.cat,

    [breakpoints.sm]: {
        fontWeight: theme.fontWeights.bold,
    },
}));

export const ContextFreightTime = styled.div(({ theme }) => ({
    gridArea: 'time',
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.chicken,

    [breakpoints.sm]: {
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.dog,
    },
}));

export const ContextFreightPrice = styled.p(({ theme }) => ({
    alignSelf: 'end',
    textTransform: 'capitalize',
    gridArea: 'price',
    justifySelf: 'end',
    fontWeight: theme.fontWeights.medium,
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.fox,

    [breakpoints.sm]: {
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.dog,
    },
}));

export const FreightDeliveryDateWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    minHeight: theme.space[7],
    justifyContent: 'space-between',

    [breakpoints.sm]: {
        alignItems: 'end',
        flexFlow: 'row',
        minHeight: 'unset',
    },
}));

export const FreightDeliveryDateLink = styled(Flex)(({ theme }) => ({
    svg: {
        marginRight: theme.space[2],
    },

    a: {
        color: theme.colors.primary,
    },
}));

export const ItemImageContainer = styled.div<{ shouldDisplayImage: boolean }>(
    ({ theme, shouldDisplayImage }) => ({
        position: 'relative',
        gridArea: 'image',
        width: shouldDisplayImage ? theme.space[12] : 0,
        height: theme.space[12],
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        transition: 'width .4s ' + theme.easings.smoothEase,
        marginRight: shouldDisplayImage ? theme.space[4] : 0,

        [breakpoints.sm]: {
            alignSelf: 'center',
        },
    }),
);

export const ItemImageWrapper = styled.div(({ theme }) => ({
    position: 'relative',
    width: theme.space[12],
    height: theme.space[12],
    padding: theme.space[2],
    backgroundColor: theme.colors.white,
    overflow: 'hidden',

    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export const ItemName = styled(Link)(({ theme }) => ({
    gridArea: 'name',
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.rat,
    alignSelf: 'center',

    [breakpoints.sm]: {
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.squirrel,
    },
}));

export const ItemNumber = styled.p<{ isCheckout?: boolean }>(
    ({ theme }) => ({
        gridArea: 'itemNumber',
        color: theme.colors.grey70,
        fontSize: theme.fontSizes.beetle,
        lineHeight: theme.lineHeights.frog,

        [breakpoints.sm]: {
            marginTop: 0,
            display: 'flex',
            fontSize: theme.fontSizes.mouse,
            lineHeight: theme.lineHeights.chicken,
            alignSelf: 'end',
        },
    }),
    ifProp('isCheckout', ({ theme }) => ({ paddingTop: theme.space[4] })),
);

export const ItemPrice = styled.p(({ theme }) => ({
    gridArea: 'price',
    textAlign: 'end',
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.squirrel,
    fontWeight: theme.fontWeights.medium,
    alignSelf: 'center',
    whiteSpace: 'nowrap',
}));

export const ItemPricePerItem = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.squirrel,
    color: theme.colors.grey90,
}));

export const ItemQuantityContainer = styled.div(({ theme }) => ({
    alignItems: 'baseline',
    display: 'grid',
    marginTop: theme.space[1],
    gridArea: 'amount',
    gridTemplateColumns: '120px 1fr 1fr',

    [breakpoints.sm]: {
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto',
    },
}));

export const ItemAmountSelector = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[3],
}));

export const ItemType = styled.p(({ theme }) => ({
    gridArea: 'type',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.rat,
    alignSelf: 'center',

    [breakpoints.sm]: {
        lineHeight: theme.lineHeights.chicken,
    },
}));

export const ItemVariant = styled.p(({ theme }) => ({
    gridArea: 'variant',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.rat,
    alignSelf: 'center',

    [breakpoints.sm]: {
        fontSize: theme.fontSizes.mouse,
        lineHeight: theme.lineHeights.chicken,
        marginTop: theme.space[1],
    },
}));

export const ItemTypeVariant = styled.span({
    textTransform: 'capitalize',
});

export const ItemWrapper = styled.li({
    width: '100%',
});

export const PriceWrapper = styled.div({ display: 'flex', justifyContent: 'end' });

export const Remove = styled.button({
    justifySelf: 'end',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',

    svg: {
        width: 20,
        height: 20,
    },
});
